.user__container {
  .header {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding-bottom: 30px;
  }

  .hover:hover {
    color: #076ae1;
    cursor: pointer;
  }
}
