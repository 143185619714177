.mainLayout__container {
    display: flex;
    background-color: $main-background;

    .mainLayout__content {
        height: 100vh;
        overflow: auto;
        flex-grow: 1;
    }

    .mainLayout__appBarSpacer {
        min-height: 64px;
    }

    .MuiDrawer-paperAnchorDockedLeft {
        background-color: $drawer-color;
    }

    .MuiAppBar-root {
       background-color: $appBar-color!important;
    }
    .MuiPaper-elevation4 {
        box-shadow: none;
    }
    .MuiDrawer-paperAnchorDockedLeft {
        border-right: none;
    }
}
