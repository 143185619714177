.login__container {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .login__avatar {
        margin: 0.5rem;
        background-color: $primary;
    }

    form {
        width: "100%"; // Fix IE 11 issue.
        margin: 0.5rem;
    }

    button {
        margin-top: 1rem;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0.5rem;
    }
}

.login__backdrop {
    z-index: 1201;
    background: $layout-text;
    .login__spinner {
        position: absolute;
    }
}
