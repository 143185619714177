.loan__container {
  .header {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding-bottom: 30px;
  }
  .user__button:hover {
    color: rgb(65, 65, 196);
    cursor: pointer;
  }

  .paper-block {
    height: 150px;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #f0f0f0 !important;

    .typography {
      display: flex;
      align-items: center;
      color: #076ae1;
      font-weight: bold;
    }
  }

  .margin {
    margin-top: 30px;
  }

  .fields-inline {
    @include flex-center;
    .text-box {
      display: inline-block;
      text-decoration: underline;
      width: 50%;
    }
  }

  .fields-space-between {
    @include flex-between;
  }
}
