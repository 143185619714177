body {
  background-color: $splashscreen-background;
}

.app__content {
  color: $layout-text !important;
  padding: 1.5rem;
  .MuiPaper-root {
    padding: 1rem;
  }
}

.nav__header__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .nav__header__container {
    justify-content: space-between;
    .MuiSvgIcon-root {
      color: $drawer-color;
    }
  }
}
.nav__header__options {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table__wrapper {
  & > div {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  cursor: pointer;
}

// Custom Scrollbar
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $main-background;
}

.MuiPaper-elevation1 {
  padding: 20px !important;
  border-radius: 5px !important;

  -webkit-box-shadow: rgba(0, 0, 0, 0.01) 0 1px 1px !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.01) 0 1px 1px !important;
  box-shadow: rgba(0, 0, 0, 0.01) 0 1px 1px !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border: 1px solid $primary;
}

.MuiPaper-root {
  background-color: $page-background !important;
}

.css-mkzahr-MuiListSubheader-root {
  background-color: $drawer-color !important;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
}
@mixin flex-between {
  display: flex;
  justify-content: space-evenly;
}
