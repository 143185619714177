$primary: #90caf9;
$secondary: #ce93d8;
$drawer-color: #f5f5f5;
$splashscreen-background: #f5f5f5;
$page-background: #f5f5f5;
$appBar-color: #f5f5f5;
$layout-text: #8a8a8a;
$main-background: #ececec;

// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Catamaran:400,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

$Roboto: "Roboto", sans-serif;
$Raleway: "Raleway", sans-serif;
$Catamaran: "Catamaran", sans-serif;
$Open-Sans: "Open Sans", sans-serif;
$Lato: "Lato", sans-serif;
$Montserrat: "Montserrat", sans-serif;
